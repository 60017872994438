import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { mq, typography } from "styles"

export const ItemContainer = styled.div`
  margin: auto;
  width: 66.66vw;
  ${mq.minWidth("md")} {
    width: 100%;
  }
`

export const inlineImgStyles = css`
  width: 90px;
  height: 90px;
`

export const ItemImage = styled(GatsbyImage)`
  margin: auto;
  width: 90px;
  height: 90px;
`

export const ItemText = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 16px;
  p {
    ${typography.bodySmall};
    margin-bottom: 0;
  }
`
