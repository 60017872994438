import React, { useContext } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import { ModeType } from "typings/modules"
import {
  Container,
  Items,
  Content,
  ButtonContainer,
} from "./ValuePropList.styles"
import { SanityValuePropList, SanityValuePropItem } from "typings/graphql"
import { Carousel } from "components/UI/Carousel/Carousel"
import { Button, ButtonVariant } from "components/UI/Button/Button"
import { ValuePropItem } from "components/ValuePropItem/ValuePropItem"
import { tracking } from "utils/tracking"
import {
  LargeAndUp,
  MediumAndBelow,
} from "components/UI/MediaQueryContainers/MediaQueryContainers"
import { PortableText } from "components/UI/PortableText/PortableText"

const carouselSettings = {
  className: "center",
  infinite: true,
  slidesToShow: 1,
  speed: 500,
}

type Props = {
  index: number
} & SanityValuePropList

export const ValuePropList = (sanityProps: Props) => {
  const themeContext = useContext(ThemeContext)
  const block = sanityProps?.block as SanityValuePropItem[]
  const internalName = sanityProps?.internalName
  const bgColor = sanityProps?.bgColor?.opacityHex
  const mode = (sanityProps?.mode ?? "dark") as ModeType
  const content = sanityProps?._rawContent
  const ctaText = sanityProps.CtaText
  const ctaLink = sanityProps.CtaLink
  return (
    <ThemeProvider
      theme={{
        localTheme: themeContext[mode],
      }}
    >
      <Container $bgColor={bgColor}>
        {content && (
          <Content>
            <PortableText blocks={content} $mode={mode} />
          </Content>
        )}
        <MediumAndBelow>
          <Carousel settings={carouselSettings}>
            {block?.map((item, i) => (
              <ValuePropItem
                key={`mobile-item-${i}`}
                index={i}
                item={item}
                mode={mode}
              />
            ))}
          </Carousel>
        </MediumAndBelow>
        <LargeAndUp>
          <Items $doublePad={block.length <= 4}>
            {block?.map((item, i) => (
              <ValuePropItem
                key={`item-${i}`}
                index={i}
                item={item}
                mode={mode}
              />
            ))}
          </Items>
        </LargeAndUp>
        {ctaText && ctaLink && (
          <ButtonContainer>
            <Button
              to={ctaLink}
              type="button"
              variant={`primary-${mode}` as ButtonVariant}
              onClick={() => {
                tracking.elementClicked(internalName, "Value Prop List")
              }}
            >
              {ctaText}
            </Button>
          </ButtonContainer>
        )}
      </Container>
    </ThemeProvider>
  )
}
