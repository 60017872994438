import React from "react"
import { SanityValuePropList } from "typings/graphql"
import { ValuePropList } from "./ValuePropList"

export const SanityValuePropListBlock = ({
  index,
  fields,
}: {
  fields: SanityValuePropList
  index: number
}) => <ValuePropList index={index} {...fields} />

export default SanityValuePropListBlock
