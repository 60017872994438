import styled from "styled-components"
import { mq } from "styles"
import { $BgColor } from "typings/modules"

export const Container = styled.div<$BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  padding: 32px 0 48px;
  ${mq.minWidth("md")} {
    padding: 48px 0;
  }
  text-align: center;
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  .slick-slider {
    padding-bottom: 32px;
  }
`

export const Content = styled.div`
  text-align: center;
  margin: auto;
  padding: 32px 0;
  width: 83.33vw;
  ${mq.minWidth("md")} {
    padding: 32px 0 48px;
    width: 75vw;
  }
  ${mq.minWidth("lg")} {
    padding: 48px 0;
    width: 41.66vw;
  }
  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ButtonContainer = styled.div`
  padding-top: 10px;
  ${mq.minWidth("md")} {
    padding-top: 20px;
  }
`

export const Items = styled.div<{ $doublePad: boolean }>`
  ${mq.minWidth("md")} {
    padding: 0 12.5vw;
    overflow-x: scroll;
    white-space: nowrap;

    & > div {
      vertical-align: top;
      display: inline-block;
      width: 25vw;
      margin-right: 6.25vw;
      white-space: normal;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  ${mq.minWidth("lg")} {
    padding: 0 4.16vw;
    overflow-x: hidden;
    & > div {
      width: 16.672vw;
      margin-right: ${({ $doublePad }) => ($doublePad ? "4.16vw" : "2.08vw")};
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`
