import { PortableText } from "components/UI/PortableText/PortableText"
import React from "react"
import { Maybe, SanityValuePropItem } from "typings/graphql"
import { ModeType } from "typings/modules"
import { generateAltContentIndex } from "utils/altTextUtils"
import { getImage } from "utils/imageUtils"
import { ItemContainer, ItemImage, ItemText } from "./ValuePropItem.styles"

type Props = {
  item: Maybe<SanityValuePropItem>
  index: number
  mode: ModeType
}

export const ValuePropItem = ({ item, index, mode }: Props) => {
  const image = getImage(item?.image)
  const content = item?._rawContent
  const altText =
    image?.description ||
    generateAltContentIndex(content, index, item?.internalName)
  return (
    <ItemContainer>
      <ItemImage image={image?.gatsbyImage} alt={altText} />
      {content && (
        <ItemText>
          <PortableText blocks={content} $mode={mode} />
        </ItemText>
      )}
    </ItemContainer>
  )
}
